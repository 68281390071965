var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "unsubscribeRoot" }, [
    _c(
      "div",
      { staticClass: "logoWrapper" },
      [
        _c("LogoImage", {
          staticClass: "logoImg",
          attrs: { width: 192, logoType: "standalone", includeDesc: false }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "headingTypeA" }, [
      _vm._v("Unsubscribe from Email Alerts")
    ]),
    _vm._v(" "),
    _vm.error
      ? _c("div", [
          _c("div", { staticClass: "errorMsg" }, [_vm._v(_vm._s(_vm.error))]),
          _vm._v(" "),
          _c("div", { staticClass: "tryAgainBtnWrapper" }, [
            _c("input", {
              staticClass: "medButton primaryColorButton",
              attrs: { type: "button", value: "Refresh" },
              on: { click: _vm.QuerySubscriptionStatus }
            })
          ])
        ])
      : _vm.email
      ? _c("div", [
          _c("div", { staticClass: "address" }, [
            _c("span", { staticClass: "inlineHeading" }, [_vm._v("Address:")]),
            _vm._v(" " + _vm._s(_vm.email))
          ]),
          _vm._v(" "),
          _vm.subscriptions.length > 0
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "currentSubscriptions inlineHeading" },
                  [_vm._v("Current Subscriptions:")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "subscriptions" },
                  _vm._l(_vm.subscriptions, function(sub) {
                    return _c(
                      "div",
                      { key: sub.typeId, staticClass: "subscription" },
                      [
                        _c("label", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: sub.selected,
                                expression: "sub.selected"
                              }
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(sub.selected)
                                ? _vm._i(sub.selected, null) > -1
                                : sub.selected
                            },
                            on: {
                              change: function($event) {
                                var $$a = sub.selected,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        sub,
                                        "selected",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        sub,
                                        "selected",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(sub, "selected", $$c)
                                }
                              }
                            }
                          }),
                          _vm._v(" " + _vm._s(sub.name))
                        ])
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c("input", {
                  staticClass: "medButton primaryColorButton",
                  attrs: { type: "button", value: "Unsubscribe" },
                  on: { click: _vm.UnsubscribeClicked }
                })
              ])
            : _c("div", { staticClass: "noSubscriptions" }, [
                _vm._v("\n\t\t\tNo Subscriptions\n\t\t")
              ])
        ])
      : _c(
          "div",
          { staticClass: "loading" },
          [
            _c("ScaleLoader", { staticClass: "animation" }),
            _vm._v(" "),
            _c("div", { staticClass: "loadingtxt" }, [_vm._v("Loading…")])
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }