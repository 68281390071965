import { CallServer } from "tdsAppRoot/API/apibase.js";



// Gets a TOC node and all of its children, if any, from the server.
var GetInstLoginData = function (store) {

	return CallServer("ShibApi", "GetInstLoginData", null, store, window.location.search);
};

export { GetInstLoginData };