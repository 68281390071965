<template>
	<div class="unsubscribeRoot">
		<div class="logoWrapper">
			<LogoImage :width="192" class="logoImg" logoType="standalone" :includeDesc="false" />
		</div>
		<div class="headingTypeA">Unsubscribe from Email Alerts</div>
		<div v-if="error">
			<div class="errorMsg">{{error}}</div>
			<div class="tryAgainBtnWrapper">
				<input type="button" class="medButton primaryColorButton" value="Refresh" @click="QuerySubscriptionStatus" />
			</div>
		</div>
		<div v-else-if="email">
			<div class="address"><span class="inlineHeading">Address:</span> {{email}}</div>
			<div v-if="subscriptions.length > 0">
				<div class="currentSubscriptions inlineHeading">Current Subscriptions:</div>
				<div class="subscriptions">
					<div class="subscription" v-for="sub in subscriptions" :key="sub.typeId">
						<label><input type="checkbox" v-model="sub.selected" /> {{sub.name}}</label>
					</div>
				</div>
				<input type="button" class="medButton primaryColorButton" value="Unsubscribe" @click="UnsubscribeClicked" />
			</div>
			<div class="noSubscriptions" v-else>
				No Subscriptions
			</div>
		</div>
		<div v-else class="loading">
			<ScaleLoader class="animation" />
			<div class="loadingtxt">Loading…</div>
		</div>
	</div>
</template>
<script>
	import { SubscriptionStatus, Unsubscribe } from 'tdsAppRoot/API/AlertsAPI.js';
	import { ReportError, ShowErrorWindow } from "tdsAppRoot/library/ErrorReporter.js";
	import ScaleLoader from 'tdsAppRoot/library/3rdParty/ScaleLoader.vue';
	import LogoImage from 'tdsAppRoot/components/Controls/LogoImage.vue';

	export default {
		components: { ScaleLoader, LogoImage },
		data()
		{
			return {
				error: "Uh, OH!",
				email: null,
				subscriptions: null
			};
		},
		mounted()
		{
			this.QuerySubscriptionStatus();
		},
		methods:
		{
			QuerySubscriptionStatus()
			{
				this.error = null;
				this.email = null;
				this.subscriptions = null;
				SubscriptionStatus(this.$store, location.search).then(result =>
				{
					this.email = result.subscriptionStatus.email;
					for (let i = 0; i < result.subscriptionStatus.subscriptions.length; i++)
						result.subscriptionStatus.subscriptions[i].selected = false; // Add a "selected" boolean field
					this.subscriptions = result.subscriptionStatus.subscriptions;
				}
				).catch(err =>
				{
					if (err.name === "ApiError")
						this.error = err.message;
					else
					{
						ReportError(this.$store.getters.urlRoot, err.message, null, err);
						this.error = "An unexpected error occurred. Technical support has been notified";
					}
				});
			},
			UnsubscribeClicked()
			{
				let typeIds = [];
				for (let i = 0; i < this.subscriptions.length; i++)
				{
					if (this.subscriptions[i].selected)
						typeIds.push(this.subscriptions[i].typeId);
				}
				if (typeIds.length === 0)
				{
					ShowErrorWindow("To unsubscribe, you must select at least one subscription.");
					return;
				}
				this.error = null;
				this.email = null;
				this.subscriptions = null;
				Unsubscribe(this.$store, location.search, typeIds).then(result =>
				{
					this.email = result.subscriptionStatus.email;
					for (let i = 0; i < result.subscriptionStatus.subscriptions.length; i++)
						result.subscriptionStatus.subscriptions[i].selected = false; // Add a "selected" boolean field
					this.subscriptions = result.subscriptionStatus.subscriptions;
				}
				).catch(err =>
				{
					if (err.name === "ApiError")
						ShowErrorWindow(err.message);
					else
					{
						ReportError(this.$store.getters.urlRoot, err.message, null, err);
						ShowErrorWindow("An unexpected error occurred. Technical support has been notified");
					}
				});
			}
		}
	}
</script>
<style scoped>
	.unsubscribeRoot
	{
		box-sizing: border-box;
		padding: 10px;
		max-width: 500px;
		text-align: left;
	}

	.logoWrapper
	{
		width: 100%;
		display: flex;
		justify-content: center;
		text-align: center;
		overflow: hidden;
		margin-bottom: 20px;
	}

	.logoImg
	{
		width: 192px;
		height: 122px;
		fill: var(--primary-color);
	}

	.loading
	{
		padding: 10px 20px;
		text-align: center;
	}

	.errorMsg,
	.subscriptions,
	.address
	{
		margin-bottom: 1em;
	}

	.inlineHeading
	{
		font-weight: bold;
	}

	.currentSubscriptions
	{
		margin-bottom: 0.5em;
	}

	.noSubscriptions
	{
		font-weight: bold;
		font-size: 14pt;
	}
	.subscription
	{
		margin-bottom: 0.33em;
	}
</style>