import { CallServer } from "tdsAppRoot/API/apibase.js";

// Mirrors AlertListRequest on server
var AlertListRequest = function ()
{
	var alertSystemId; // EVIDENCE: 1, TR: 2
	var maxItems; // [1-150] The effective minimum for EVIDENCE is 3.
	var suppress3ppLogin; // type: Boolean
};
Object.seal(AlertListRequest);

// Mirrors AlertArticleRequest on server
var AlertArticleRequest = function ()
{
	var alertSystemId; // EVIDENCE: 1, TR: 2
	var articleId; // type: String
	var retrievalKey; // type: String
};
Object.seal(AlertArticleRequest);

// Mirrors AlertSetPreferencesRequest on server
var AlertSetPreferencesRequest = function ()
{
	var alertSystemId; // EVIDENCE: 1, TR: 2
	var prefs; // type: Object
};
Object.seal(AlertSetPreferencesRequest);

var AlertSubscriptionStatusRequest = function ()
{
	var query; // Query String from URL
};
Object.seal(AlertSubscriptionStatusRequest);

var AlertUnsubscribeRequest = function ()
{
	var query; // Query String from URL
	var typeIds; // Array of integers (EVIDENCE: 1,2,3, TR: 4, EEPLUS: 5)
};
Object.seal(AlertUnsubscribeRequest);

export function GetAlertList(store, alertSystemId, maxItems, suppress3ppLogin)
{
	try
	{
		let myargs = new AlertListRequest();
		myargs.alertSystemId = alertSystemId;
		myargs.maxItems = maxItems;
		myargs.suppress3ppLogin = suppress3ppLogin;

		return CallServer("AlertData", "ArticleList", myargs, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}
export function GetAlertArticle(store, alertSystemId, articleId, retrievalKey)
{
	try
	{
		let myargs = new AlertArticleRequest();
		myargs.alertSystemId = alertSystemId;
		myargs.articleId = articleId;
		myargs.retrievalKey = retrievalKey;

		return CallServer("AlertData", "Article", myargs, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}
export function GetRssLists(store, alertSystemId)
{
	try
	{
		let myargs = new AlertListRequest();
		myargs.alertSystemId = alertSystemId;

		return CallServer("AlertData", "RssLists", myargs, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}
export function GetPreferences(store, alertSystemId)
{
	try
	{
		let myargs = new AlertListRequest();
		myargs.alertSystemId = alertSystemId;

		return CallServer("AlertData", "GetPreferences", myargs, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}
export function SetPreferences(store, alertSystemId, prefs)
{
	try
	{
		let myargs = new AlertSetPreferencesRequest();
		myargs.alertSystemId = alertSystemId;
		myargs.prefs = prefs;

		return CallServer("AlertData", "SetPreferences", myargs, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}
export function SubscriptionStatus(store, query)
{
	try
	{
		let myargs = new AlertSubscriptionStatusRequest();
		myargs.query = query;

		return CallServer("AlertUnsubscribe", "SubscriptionStatus", myargs, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}
export function Unsubscribe(store, query, typeIds)
{
	try
	{
		let myargs = new AlertUnsubscribeRequest();
		myargs.query = query;
		myargs.typeIds = typeIds;

		return CallServer("AlertUnsubscribe", "Unsubscribe", myargs, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}