<template>
	<div class="pendingLoginProduct">
		<span :class="{ itemText: available }">{{item.name}}</span>
		<Tooltip>
			<TitleInfo :fxid="item.id" />
		</Tooltip>
	</div>
</template>

<script>
	import Tooltip from 'tdsAppRoot/components/Controls/Tooltip.vue';
	import TitleInfo from 'tdsAppRoot/components/Controls/TitleInfo.vue';
	export default {
		components: { Tooltip, TitleInfo },
		props:
		{
			item: {
				type: Object,
				required: true
			},
			available: {/* If true, list available items.  If false, list unavailable items. */
				type: Boolean,
				required: true
			}
		}
	}
</script>

<style scoped>
	.pendingLoginProduct
	{
		display: inline-block;
	}
	.itemText
	{
		cursor: pointer;
		color: #2222DD;
	}
</style>