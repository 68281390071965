<template>
	<div class="loginPanel">
		<div class="loginTopBar">
			<LogoImage class="logoImg" :includeDesc="true" :width="280" :height="84" logoType="onPrimaryColor" :rolePresentation="true" />
		</div>
		<router-view></router-view>
	</div>
</template>

<script>
	import ThemeMixin from "tdsAppRoot/library/ThemeMixin.js";
	import LogoImage from "tdsAppRoot/components/Controls/LogoImage.vue";

	export default {
		mixins: [ThemeMixin],
		components: { LogoImage },
		props:
		{
		},
		data()
		{
			return {
			};
		},
		methods:
		{
		},
		computed:
		{
		}
	}
</script>

<style scoped>

	.loginPanel
	{
		margin: 10vh 0px;
		box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
		border-radius: 5px;
	}

	.loginTopBar
	{
		background-color: var(--primary-color);
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
	}

	.logoImg
	{
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0px auto;
		padding: 20px;
		fill: var(--text-color-on-primary-color);
	}
</style>