<template>

	<div class="instLoginRoot">
		<div class="instLoginHeader">Institutional Login</div><br />

		<div v-if="federations && federations.length > 0">
			<div v-if="federations.length > 1" class="fedListBox">
				<select name="federations" id="federations" v-model="selectedFed" class="fedList">
					<option v-for="fed in federations" :value="fed.id">{{ fed.name }}</option>
				</select>
			</div>
			<div v-else>
				<label id="singleFedLabel">{{ federations[0].name }}</label>
				<input type="hidden" name="federations" :value="federations[0].id" />
			</div>

			<div class="instListBox">
				<select name="institutions" id="institutions" class="instList" v-model="selectedInst" @dblclick="OnSelectInstitutionClick" v-bind:size="Object.keys(institutions[selectedFed]).length > 12 ? 12 : Object.keys(institutions[selectedFed]).length">
					<option v-for="inst in institutions[selectedFed]" :value="inst.grpalias + ':' + inst.entityid">{{ inst.name }}</option>
				</select>
			</div>
			<input type="button" class="commonLoginButton" value="Select Institution" @click="OnSelectInstitutionClick" />
		</div>
		<div v-else>
			<ScaleLoader class="animation" />
			<div class="loadingTxt">
				Please Wait
			</div>
		</div>
	</div>
</template>

<script>
	import { GetInstLoginData } from 'tdsAppRoot/API/ShibApi.js';
	import ScaleLoader from 'tdsAppRoot/library/3rdParty/ScaleLoader.vue';

	import { ReportError, ShowErrorWindow } from "tdsAppRoot/library/ErrorReporter.js";
	import { ModalMessageDialog } from "tdsAppRoot/library/ModalDialog.js";

	export default {
		components: { ScaleLoader },
		data: function () {
			return {
				federations: [],
				institutions: [],
				selectedFed: "UK_Federation",
				selectedInst: null
			}
		},
		mounted: function () {
			let saveThis = this;
			GetInstLoginData(this.$store).then(data =>
			{
				saveThis.$data.federations = data.federations;
				saveThis.$data.institutions = data.institutions;
			}).catch(err =>
			{
				ReportError(this.$store.getters.urlRoot, "GetInstLoginData failed with error " + err.message);
			});
		},
		methods: {
			OnSelectInstitutionClick: function () {
				if (this.selectedInst)
					window.location.href = this.$store.getters.urlRoot + "ShibApi/ExecuteShibLogin?fed=" + this.$data.selectedFed + "&selectedInst=" + this.$data.selectedInst;
				else
					ModalMessageDialog("Please select an institution first.");
			}
		}
	}
</script>

<style scoped>
	.instLoginRoot
	{
		padding: 10px;
		
		min-width: 320px;
		box-sizing: border-box;
	}

	.instLoginHeader
	{
		font-weight: bold;
		text-align: center;
		font-size: 20pt;
	}

	.instListBox
	{
		width: 100%;
		margin-bottom: 20px;
	}

	.instList
	{
		width: 100%;
	}

	.fedListBox
	{
		margin-bottom: 5px;
	}

	.fedList
	{
	}

	.loadingTxt
	{
		margin-top: 5px;
		width: 100%;
		text-align: center;
		font-size: 18pt;
	}
</style>