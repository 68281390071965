var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "unpwBody" },
    [
      _c("div", { staticClass: "commonLoginInputs" }, [
        _c(
          "div",
          { staticClass: "loginInputDiv", attrs: { title: "User name" } },
          [
            _c("vsvg", {
              staticClass: "inputIcon",
              attrs: {
                sprite: "#person_fill",
                "aria-hidden": "true",
                title: "",
                desc: ""
              }
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.userName,
                  expression: "userName"
                }
              ],
              attrs: {
                type: "text",
                id: "username",
                "aria-label": "User name",
                autocomplete: "username",
                autocorrect: "off",
                autocapitalize: "none",
                autofocus: "",
                placeholder: "User name",
                tabindex: "0"
              },
              domProps: { value: _vm.userName },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.userName = $event.target.value
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "loginInputDiv", attrs: { title: "Password" } },
          [
            _c("vsvg", {
              staticClass: "inputIcon",
              attrs: {
                sprite: "#unlock",
                "aria-hidden": "true",
                title: "",
                desc: ""
              }
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.password,
                  expression: "password"
                }
              ],
              attrs: {
                type: "password",
                id: "password",
                "aria-label": "Password",
                autocomplete: "current-password",
                placeholder: "Password",
                tabindex: "0"
              },
              domProps: { value: _vm.password },
              on: {
                keypress: _vm.KeyPress,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.password = $event.target.value
                }
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("input", {
        staticClass: "commonLoginButton",
        attrs: { type: "button", value: "Log in", tabindex: "0" },
        on: {
          click: function($event) {
            return _vm.LoginClick()
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ssoLinks" },
        [
          _c(
            "a",
            {
              staticClass: "athensLoginLink",
              attrs: { href: _vm.athensLoginLink },
              on: { click: _vm.AthensLoginClick }
            },
            [_vm._v("Athens Login")]
          ),
          _vm._v(" "),
          _vm.runningAtRoot
            ? _c(
                "router-link",
                {
                  staticClass: "instLoginLink",
                  attrs: { to: { name: "instLogin" } }
                },
                [_vm._v("Institutional Login")]
              )
            : _c(
                "a",
                {
                  staticClass: "instLoginLink",
                  attrs: { href: "/instLogin.aspx" }
                },
                [_vm._v("Institutional Login")]
              ),
          _vm._v(" "),
          _c("div", { staticClass: "extraLinksRowSep" }),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "forgotPwLink",
              attrs: { to: { name: "forgotPassword" } }
            },
            [_vm._v("Forgot Password?")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("FullScreenLoadingMsg", {
        attrs: { show: _vm.loading, msg: "Logging In" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }