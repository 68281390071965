<template>
	<div class="unpwBody">
		<div class="commonLoginInputs">
			<div title="User name" class="loginInputDiv">
				<vsvg class="inputIcon" sprite="#person_fill" aria-hidden="true" title="" desc="" />
				<input type="text" id="username" aria-label="User name" autocomplete="username" autocorrect="off" autocapitalize="none" v-model="userName" autofocus placeholder="User name" tabindex="0" />
			</div>
			<div title="Password" class="loginInputDiv">
				<vsvg class="inputIcon" sprite="#unlock" aria-hidden="true" title="" desc="" />
				<input type="password" id="password" aria-label="Password" autocomplete="current-password" v-model="password" v-on:keypress="KeyPress" placeholder="Password" tabindex="0" />
			</div>
		</div>
		<input class="commonLoginButton" type="button" value="Log in" v-on:click="LoginClick()" tabindex="0" />
		<div class="ssoLinks">
			<a :href="athensLoginLink" @click="AthensLoginClick" class="athensLoginLink">Athens Login</a>
			<router-link v-if="runningAtRoot" :to="{ name: 'instLogin' }" class="instLoginLink">Institutional Login</router-link>
			<a v-else="runningAtRoot" href="/instLogin.aspx" class="instLoginLink">Institutional Login</a>
			<div class="extraLinksRowSep"></div>
			<router-link :to="{ name: 'forgotPassword' }" class="forgotPwLink">Forgot Password?</router-link>
		</div>
		<FullScreenLoadingMsg :show="loading" msg="Logging In" />
	</div>
</template>

<script>
	import { ReportError, ShowModalErrorWindow, ShowCustomErrorWindow } from "tdsAppRoot/library/ErrorReporter.js";
	import svg2 from "tdsAppRoot/images/sprite/unlock.svg";
	import svg3 from "tdsAppRoot/images/sprite/person_fill.svg";
	import { StartSession } from 'tdsAppRoot/library/Session.js';
	import FullScreenLoadingMsg from "tdsAppRoot/components/Controls/FullScreenLoadingMsg.vue";
	import URLParse from 'url-parse';
	import EventBus from 'tdsAppRoot/library/EventBus.js';
	import { escapeRegExp, LoadDeviceGuidForMFA } from 'tdsAppRoot/library/TDSUtil.js';
	import { ModalMFADialog, ModalTempLoginExpiredDialog } from "tdsAppRoot/library/ModalDialog.js";
	import { DecryptString } from "tdsAppRoot/library/cryptds.js";

	export default {
		components: { FullScreenLoadingMsg },
		props:
		{
			autologinToken: {
				type: String,
				default: ""
			}
		},
		data()
		{
			return {
				userName: "",
				password: "",
				loading: false,
				mfaDialogIsOpen: false
			};
		},
		computed:
		{
			athensLoginLink()
			{
				if (this.runningAtRoot)
				{
					return this.$store.getters.urlRoot + "AthensLogin";
				}
				else
					return "/AthensLogin.aspx";
			},
			runningAtRoot()
			{
				if (location.pathname.substr(0, "/TDSHealth".length) == "/TDSHealth")
				{
					return false;
				}
				return true;
			}
		},
		watch:
		{
			userName()
			{
				this.$store.commit("SetLoginUserName", this.userName); // So the field can be auto-populated in ForgotPassword.vue
			}
		},
		created()
		{
			if (this.$store.state.$loginUserName)
				this.userName = this.$store.state.$loginUserName;
		},
		mounted()
		{
			try
			{
				if (this.autologinToken)
				{
					let jsonStr = DecryptString(this.autologinToken, "aX*N2sL^me#3RUA3");
					let args = JSON.parse(jsonStr);
					console.log("Logging in automatically based on autologinToken.");
					this.userName = args.un;
					this.password = args.pw;
					this.LoginClick();
				}
			}
			catch (err)
			{
				ReportError(this.$store.getters.urlRoot, "Login error: autologinToken failed to decode", null, err);
			}
		},
		methods: {
			KeyPress(event)
			{
				if (event.keyCode === 13 || event.key === "enter")
					this.LoginClick();
			},
			LoginClick(mfaArgs)
			{
				this.$store.commit("SetLoginUserName", null);
				if (!this.$data.userName)
					ShowModalErrorWindow("Please enter a user name");
				else if (!this.$data.password)
					ShowModalErrorWindow("Please enter a password");
				else
				{
					if (this.$data.loading)
						return;
					this.$data.loading = true;
					var currentFocus = typeof document !== 'undefined' ? document.activeElement : null;
					let path = null;
					var uAddr = URLParse(window.location.href, true);
					path = typeof uAddr.query.path !== 'undefined' ? uAddr.query.path : null;
					if (path)
					{
						if (typeof window !== 'undefined')
						{
							let appPath = this.$store.state.appPath;
							if (appPath)
								appPath = appPath.cstrim('/') + '/';
							// We need to do a case insensitive replace to remove [appPath] from the [path], since it is going to be in [urlRoot]
							// We have to use Regex, so first we need to get rid of any reserved regex characters:
							var esc = escapeRegExp(appPath);
							var reg = new RegExp('^' + esc, 'i');
							path = path.replace(reg, "").replace("//", "/");
							if (path && path[0] == "/")
								path = path.substr(1);
							if (path.indexOf("?") == -1)
								path = path + "?";
						}
					}
					let uTargetPath = URLParse(this.$store.getters.urlRoot + (path ? path : ""), true);
					delete uTargetPath.query.un;
					delete uTargetPath.query.pw;
					delete uTargetPath.query.path;
					delete uTargetPath.query.grpalias;
					delete uTargetPath.query.productTypeInt;
					delete uTargetPath.query.target;
					delete uTargetPath.query.entityid;
					delete uTargetPath.query.product;
					delete uTargetPath.query.sessionid;
					delete uTargetPath.query.errMsg;
					delete uTargetPath.query.fromPending;
					if (uAddr.query.suppressBrowserWarning == "true")
						uTargetPath.query.suppressBrowserWarning = "true";
					uTargetPath.set("query", uTargetPath.query);

					let relativePath = location.pathname + location.search + location.hash;

					if (!mfaArgs)
					{
						// This request does not have MFA arguments. Create arguments using the saved device GUID so the user may bypass MFA if this is a remembered device.
						let savedDeviceGuid = LoadDeviceGuidForMFA();
						if (savedDeviceGuid && savedDeviceGuid.length > 1)
							mfaArgs = { mfaDeviceGuid: savedDeviceGuid };
					}

					StartSession(this.$store, this.$data.userName, this.$data.password, relativePath, mfaArgs).then(data =>
					{
						if (data.sid && data.sid.length > 0)
						{
							this.$store.commit("SetSessionID", data.sid);
							location.href = uTargetPath.href + (uTargetPath.href.indexOf("?") === -1 ? "?" : "&") + "SessionID=" + data.sid;
						}
						else
						{
							this.$data.loading = false;
							if (data.loginStatus === "Pending" && data.productDetails)
							{
								// Redirect to pending login page.
								uTargetPath.query.un = this.$data.userName;
								uTargetPath.query.pw = this.$data.password;
								uTargetPath.set("query", uTargetPath.query);

								EventBus.PendingLoginArgs = { productDetails: data.productDetails, finishPendingLoginLink: uTargetPath.href, isPopup: true, perpetualProductsOnly: data.perpetualProductsOnly, contacts: data.contacts, focus: currentFocus }; // If the login fails due to concurrency limits, product availability information goes here.
								this.$router.push({ name: 'directPendingLogin_l' });
							}
							else if (data.loginStatus === "Turnaway")
							{
								ShowCustomErrorWindow("TURNAWAY", data.statusMessage);
							}
							else if (data.loginStatus === "Failed")
							{
								if (data.loginFailureType === "mfa_challenge")
								{
									if (this.mfaDialogIsOpen)
										ShowModalErrorWindow(data.statusMessage);
									else
									{
										console.log("MFA challenge: " + data.statusMessage);
										this.mfaDialogIsOpen = true;
										ModalMFADialog("challenge", this.userName, this.password, data.mfaUserData, this.MFASubmitCallback)
											.then(result => { this.mfaDialogIsOpen = false; });
									}
								}
								else if (data.loginFailureType === "mfa_setup_required")
								{
									if (this.mfaDialogIsOpen)
										ShowModalErrorWindow(data.statusMessage);
									else
									{
										console.log("MFA Setup Required: " + data.statusMessage);
										this.mfaDialogIsOpen = true;
										ModalMFADialog("initial_setup", this.userName, this.password, data.mfaUserData)
											.then(result => { this.mfaDialogIsOpen = false; });
									}
								}
								else if (data.loginFailureType === "temp_login_expired")
								{
									let args = { user: this.userName, pass: this.password, isExpired: true, groupName: undefined, expDate: undefined };
									if (data.expiredTLAInfo)
									{
										args.groupName = data.expiredTLAInfo.groupName;
										args.expDate = data.expiredTLAInfo.expDate;
									}
									ModalTempLoginExpiredDialog(args);
								}
								else if (data.loginFailureType === "is_profile_not_login")
								{
									let args = { user: this.userName, pass: this.password, isExpired: false, groupName: undefined, expDate: undefined };
									ModalTempLoginExpiredDialog(args);
								}
								else
									ShowModalErrorWindow(data.statusMessage);
							}
							else
							{
								ShowModalErrorWindow(data.statusMessage);
							}
							return;
						}
					}).catch(err =>
					{
						this.$data.loading = false;

						if (!err.loginStatus || err.loginStatus.toLowerCase() !== 'failed')
							ReportError(this.$store.getters.urlRoot, "Login error: " + err.statusMessage + " (" + err.errMsg + ")", null, err);
						ShowModalErrorWindow(err.statusMessage);
					});
				}
			},
			AthensLoginClick()
			{
			},
			MFASubmitCallback(mfaArgs)
			{
				this.LoginClick(mfaArgs);
			}
		}
	}
</script>

<style scoped>
	.extraLinksRowSep
	{
		clear: both;
		margin-bottom: 5px;
	}

	.instLoginLink
	{
		float: right;
	}

	.unpwBody
	{
		width: 320px;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		padding: 20px;
		box-sizing: border-box;
	}

	.ssoLinks
	{
		padding-top: 5px;
	}

		.ssoLinks a
		{
			background-color: white;
		}

	input[type=text], input[type=password]
	{
		color: black;
	}
</style>