<template>
	<div class="instLogoutMsgRoot">
		You have been logged out of {{ appContext.appName }}, but you have <i>not</i> been logged out of your Identity Provider.<br /><br />
		<a href="https://www.statref.com">Click here to return to www.statref.com</a>
	</div>
</template>

<script>
	import ThemeMixin from "tdsAppRoot/library/ThemeMixin.js";

	export default {
		mixins: [ ThemeMixin ]
	};
</script>
<style scoped>
	.instLogoutMsgRoot
	{
		padding: 5px;
		max-width: 350px;
	}
</style>