<template>
	<div class="loginRoot">
		<ModalDialogContainer name="dialogFade" transition-name="dialogFade"></ModalDialogContainer>
		<ToastContainer />
		<MinorErrorIcon />
		<div id="appContentRoot" ref="root" class="contentRoot">
			<div class="errorMessage" tabindex="-1" id="errMsgDiv" v-if="errMsg && errMsg != 'null'">
				<div class="errorMessageTxt">{{ errMsg }}</div>
				<div class="dataWarning">If you are using a public computer, we recommend you close the browser tab when finished to protect any personal information which may still be in memory.</div>
			</div>
			<router-view></router-view>
			<AppFooter id="appFooter" />
		</div>
	</div>
</template>


<script>
	import AppFooter from '../AppFooter.vue';
	import { ReportError, ShowCustomErrorWindow } from "tdsAppRoot/library/ErrorReporter.js";
	import ModalDialogContainer from "tdsAppRoot/components/Controls/ModalDialogContainer.vue";
	import ToastContainer from "tdsAppRoot/components/Controls/Toast/ToastContainer.vue";
	import MinorErrorIcon from "tdsAppRoot/components/Controls/MinorErrorIcon.vue";
	import { IsSecureContext, DecodeHost, ImportingForTheStringExtensions } from 'tdsAppRoot/library/TDSUtil.js';

	export default {
		components: { AppFooter, ModalDialogContainer, ToastContainer, MinorErrorIcon },
		computed:
		{
			errMsg()
			{
				if (this.$route.name != "error" && this.$route.query.errMsg)
				{
					if (this.$route.query.errMsg[this.$route.query.errMsg.length - 1] != '.')
						return this.$route.query.errMsg + ".";
					return this.$route.query.errMsg;
				}
				return null;
			},
			turnaway()
			{
				if (this.$route.query.turnaway)
					return this.$route.query.turnaway;
				return null;
			}
		},
		created()
		{
			if (!IsSecureContext() && typeof appContext !== "undefined" && appContext.ForceHttpsIfNoProxy && appContext.originEncoded)
			{
				if (location.href.toUpperCase().startsWith("HTTP:"))
				{
					let serverOrigin = DecodeHost(appContext.originEncoded);
					if (serverOrigin.toUpperCase() === location.origin.toUpperCase())
					{
						console.log("No proxy server was detected, so the login page is redirecting to HTTPS.");
						location.href = "https" + location.href.substr(4);
					}
				}
			}
		},
		mounted()
		{
			if (typeof window !== "undefined")
			{
				window.mainLayoutRef = this.$refs.root;

				if (this.turnaway)
					ShowCustomErrorWindow("TURNAWAY", this.turnaway);

				if (this.errMsg && this.errMsg != null)
					document.getElementById("errMsgDiv").focus();
			}
		}
	}
</script>

<style scoped>
	body
	{
		margin: 0px;
		height: 100%;
		font-family: Calibri, sans-serif;
	}

	.errorMessage
	{
		max-width: 350px;
		position: relative;
		top: 20px;
		padding-left: 10px;
		padding-right: 10px;
	}

	.errorMessageTxt
	{
		color: #D40202;
		background-color: white;
	}

	.errorMessage:focus
	{
		outline: none;
	}

	.dataWarning
	{
		margin-top: 10px;
		font-size: smaller;
	}

	.loginRoot, .contentRoot
	{
		width: 100%;
		height: 100%; /* Flex layout, height 100%, etc… required for bottom-attached footer in some browsers */
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
	}
</style>