<template>
	<div class="resetPw">
		<h2>Forgot Password</h2>
		<div v-if="message && !error">
			Your {{accountTypeName}} password has been set to:
			<div class="msg">
				<div class="pwBox">{{message}}</div>
			</div>
			<div class="msg">A copy of this password has been emailed to you.</div>
		</div>
		<div v-else-if="error">
			{{message}}
		</div>
		<div v-else>…</div>
		<template v-if="isLoginAccount">
			<div class="loginLink" v-if="error">
				<router-link :to="{ name: 'forgotPassword' }">Try Again</router-link>
			</div>
			<div class="loginLink">
				<router-link :to="{ name: 'loginRoot' }">Return to Login</router-link>
			</div>
		</template>
		<FullScreenLoadingMsg :show="loading" msg="Processing…" />
	</div>
</template>

<script>
	import { SendPasswordResetCommand } from 'tdsAppRoot/API/ForgotPasswordAPI.js';
	import { ReportError } from "tdsAppRoot/library/ErrorReporter.js";
	import FullScreenLoadingMsg from "tdsAppRoot/components/Controls/FullScreenLoadingMsg.vue";

	export default {
		components: { FullScreenLoadingMsg },
		data()
		{
			return {
				loading: false,
				error: false,
				accountTypeName: "",
				message: ""
			};
		},
		mounted()
		{
			this.SendResetCommand();
		},
		methods:
		{
			SendResetCommand()
			{
				this.loading = true;
				this.error = false;
				SendPasswordResetCommand(this.$store, this.type, this.id, this.token).then(data =>
				{
					this.loading = false;
					this.message = data.message;
					this.accountTypeName = data.accountType;
				}
				).catch(err =>
				{
					this.error = true;
					this.loading = false;
					if (err.name === "ApiError")
						this.message = err.message;
					else
					{
						this.message = "An error has occurred. Technical support has been notified.";
						ReportError(this.$store.getters.urlRoot, "Forgot Password error", null, err);
					}
				});
			}
		},
		computed:
		{
			type()
			{
				return this.$route.query.type;
			},
			id()
			{
				return this.$route.query.id;
			},
			token()
			{
				return this.$route.query.token;
			},
			isLoginAccount()
			{
				return this.type === 0;
			}
		}
	}
</script>

<style scoped>
	.resetPw
	{
		width: 320px;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		padding: 20px;
		box-sizing: border-box;
	}

	.loginLink
	{
		margin-top: 20px;
		text-align: center;
	}

	h2
	{
		margin-top: 0px;
		margin-bottom: 10px;
	}

	.msg
	{
		margin-top: 10px;
	}

	.pwBox
	{
		display: inline-block;
		border: 1px solid black;
		padding: 5px 15px;
	}
</style>