<template>
	<PendingLogin v-if="pla" :productDetails="pla.productDetails" :finishPendingLoginLink="pla.finishPendingLoginLink" :isPopup="pla.isPopup" :contacts="pla.contacts" :perpetualProductsOnly="pla.perpetualProductsOnly" />
	<div v-else>
		<br /> The resources you requested are already in use. <router-link :to="{ name: 'loginRoot' }">Return to Login</router-link>
	</div>
</template>

<script>
	// DirectPendingLogin.vue is a root page for all pending logins.  For example, shibboleth pending logins.
	import EventBus from 'tdsAppRoot/library/EventBus.js';
	import PendingLogin from 'tdsAppRoot/components/Auth/PendingLogin.vue';
	export default {
		components: { PendingLogin },
		data: function ()
		{
			return {
				pla: null
			};
		},
		created()
		{
			if (!EventBus.PendingLoginArgs)
				EventBus.PendingLoginArgs = { productDetails: (typeof appContext !== "undefined" ? appContext.productDetails : null), isPopup: false };
			else
				EventBus.PendingLoginArgs.isPopup = false;
			this.pla = EventBus.PendingLoginArgs;
		}
	}
</script>