var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mfaHandover" },
    [
      _vm.error
        ? _c("div", [_vm._v(_vm._s(_vm.error))])
        : [
            _c("MFAInterface", {
              ref: "mfaInterface",
              attrs: {
                mode: _vm.mode,
                username: _vm.username,
                password: _vm.password,
                mfaUserData: _vm.mfaUserData,
                externalGuid: _vm.externalGuid,
                isExternalApp: _vm.isExternalApp
              },
              on: { close: _vm.Cancel, submit: _vm.Submitted }
            }),
            _vm._v(" "),
            _c("FullScreenLoadingMsg", {
              attrs: { show: _vm.loggingIn, msg: "Logging In" }
            })
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }