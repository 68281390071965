var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.myProducts
    ? _c("div", [
        _c("div", { staticClass: "subHeader" }, [_vm._v(_vm._s(_vm.heading))]),
        _vm._v(" "),
        _c(
          "ul",
          _vm._l(_vm.myProducts, function(item, index) {
            return _c(
              "li",
              {
                key: index,
                attrs: { role: _vm.listItemRole, tabindex: _vm.tabIndex },
                on: {
                  click: function($event) {
                    return _vm.ItemClick(item)
                  },
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "space", 32, $event.key, [
                        " ",
                        "Spacebar"
                      ]) &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.ItemClick(item)
                  }
                }
              },
              [
                _c("PendingLoginProduct", {
                  attrs: { available: _vm.available, item: item }
                })
              ],
              1
            )
          }),
          0
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }