<template>
	<div class="pendingLogin">
		<div class="logo">
			<LogoImage class="logoImg" :includeDesc="true" :width="152" logoType="onPrimaryColor" />
		</div>
		<div class="pendingLoginBody">
			<div class="mainHeader">
				Turnaway Notice
			</div>
			<div class="turnawayReason">
				{{ turnawayReason }}
			</div>
			<div class="loginLink" v-if="isDirectPendingLogin">
				<router-link :to="{ name: 'loginRoot' }" @click.native="$emit('Close');">Click here to try a different login.</router-link>
			</div>
			<template v-if="cproductDetails">
				<PendingLoginProductList class="section" :finishPendingLoginLink="finishPendingLoginLink" heading="Available Titles" :productDetails="cproductDetails" :available="true" :titles="true" />
				<PendingLoginProductList class="section" :finishPendingLoginLink="finishPendingLoginLink" heading="Available Products" :productDetails="cproductDetails" :available="true" :titles="false" />
				<PendingLoginProductList class="section" :finishPendingLoginLink="finishPendingLoginLink" heading="Unavailable Titles" :productDetails="cproductDetails" :available="false" :titles="true" />
				<PendingLoginProductList class="section" :finishPendingLoginLink="finishPendingLoginLink" heading="Unavailable Products" :productDetails="cproductDetails" :available="false" :titles="false" />
			</template>
			<div class="section contacts" v-if="typeof ccontacts !== 'undefined' && ccontacts.length > 0" v-html="ccontacts">
			</div>
		</div>
	</div>
</template>

<script>
	import PendingLoginProductList from "./PendingLoginProductList.vue";
	import ThemeMixin from "tdsAppRoot/library/ThemeMixin.js";
	import LogoImage from "tdsAppRoot/components/Controls/LogoImage.vue";

	export default {
		mixins: [ThemeMixin],
		components: { PendingLoginProductList, LogoImage },
		props:
		{
			productDetails: {
				type: Object,
				required: false
				/*
					List<string> unavailableTitleList, unavailableProductList;
					List<int> availableTitleIds;	// statref titles.
					List<int> availableProductIds; // non-statref products
					List<ProdListItem> availableProductList;

					ProdListItem
					{
						public string name;
						public int id;
						public int productType;
					}
				*/

			},
			contacts: {
				type: String,
				default: ""
			},
			finishPendingLoginLink: {
				type: String,
				default: null
			},
			perpetualProductsOnly: {
				type: Boolean,
				default: false
			}
		},
		data()
		{
			return {
				turnawayReason: "One or more of the resources you requested is already in use. You can either select another resource and continue working, or try again later."
			};
		},
		mounted()
		{
			if (!this.cproductDetails)
			{
				this.$router.replace({ name: "loginRoot" }).catch(routingError => { });
				this.$emit("Close");
			}
			if (this.$props.perpetualProductsOnly || (typeof (appContext) != "undefined" && appContext.expired))
			{
				this.$data.turnawayReason = "Your subscription is not currently active, but some products may still be available if you've used them before with your Personalized Profile Account.";
			}
			this.SetFocus();
		},
		computed:
		{
			cproductDetails: function ()
			{
				if (!this.$props.productDetails)
				{
					if (typeof (appContext) !== "undefined" && appContext.productDetails)
					{
						return appContext.productDetails;
					}
				}
				return this.$props.productDetails;
			},
			ccontacts: function ()
			{
				if (!this.$props.contacts)
				{
					if (typeof (appContext) !== 'undefined' && appContext.contacts)
					{
						return appContext.contacts;
					}
				}
				return this.$props.contacts;
			},
			isDirectPendingLogin: function ()
			{
				return this.$route.name == "directPendingLogin_l" || this.$route.name == "directPendingLogin_s";
			}
		},
		methods:
		{
			SetFocus()
			{
				if (typeof document !== 'undefined')
				{
					var target = document.getElementById('available_p0');
					if (!target)
						target = document.getElementById('available_t0');
					if (target)
						target.focus();
				}
			}
		}
	}
</script>

<style scoped>
	.pendingLogin
	{
		width: 100%;
	}

	.logo
	{
		background-color: var(--primary-color);
		padding: 10px;
		margin-bottom: 20px;
	}

	.turnawayReason
	{
		margin-top: 12px;
		color: initial;
		font-size: initial;
	}

	.section
	{
		margin-top: 12px;
	}

	.mainHeader
	{
		color: #6699cc;
		font-size: 17px;
	}

	.loginLink
	{
		margin-top: 15px;
	}

	.pendingLoginBody
	{
		padding: 10px;
	}

	@media (min-width: 600px)
	{
		.pendingLoginBody
		{
			padding: 10px 30px;
		}
	}
</style>