<template>
	<div class="mfaHandover">
		<div v-if="error">{{error}}</div>
		<template v-else>
			<MFAInterface ref="mfaInterface"
						  @close="Cancel"
						  :mode="mode"
						  :username="username"
						  :password="password"
						  :mfaUserData="mfaUserData"
						  :externalGuid="externalGuid"
						  :isExternalApp="isExternalApp"
						  @submit="Submitted" />
			<FullScreenLoadingMsg :show="loggingIn" msg="Logging In" />
		</template>
	</div>
</template>


<script>
	// This MFAHandover component exists to facilitate loading an MFA validation user interface into an iframe or web browser control in external apps.
	// This component can communicate with the server to perform initial forced MFA setup, but cannot do standard login to create a session.
	// When this component is used to complete an MFA Authentication Challenge as part of a user login, it will call the [mfaSubmitCallback] with the one-time password or other secret information.  It is the responsibility of the calling app to export this data via whatever means possible and call the necessary server APIs to complete user authentication.

	import FullScreenLoadingMsg from "tdsAppRoot/components/Controls/FullScreenLoadingMsg.vue";
	import MFAInterface from "tdsAppRoot/components/MFA/MFAInterface.vue";
	import { DecryptString } from "tdsAppRoot/library/cryptds.js";

	export default {
		components: { FullScreenLoadingMsg, MFAInterface },
		props:
		{
			handoverArgs: { // MFAHandoverArgs, json serialized and encrypted
				type: String,
				default: ""
			},
			externalGuid: { // External apps can provide their GUID string here. Ignored if [isExternalApp] is false.
				type: String,
				default: ""
			},
			isExternalApp: { // If true, the Device GUID comes from the [externalGuid] prop and is understood to be stored externally.
				type: Boolean,
				default: false
			}
		},
		data()
		{
			return {
				loggingIn: false,
				error: "Loading…",
				mode: '',
				username: '',
				password: '',
				mfaUserData: null,
			};
		},
		created()
		{
			try
			{
				if (this.handoverArgs)
				{
					let jsonStr = DecryptString(this.handoverArgs, "miMyj*T2dPaKCpCB");
					let args = JSON.parse(jsonStr);
					if (args.mode && args.ud)
					{
						if (this.mode !== "setup")
						{
							this.mode = args.mode; // Required argument.
							this.username = args.un; // Provided if this is forced initial MFA configuration.
							this.password = args.pw; // Provided if this is forced initial MFA configuration.
							this.mfaUserData = args.ud; // Required argument.
							this.SetError(null);
						}
						else
							this.SetError("This Multi-Factor Authentication interface does not have \"setup\" mode implemented.");
					}
					else
					{
						this.SetError("This Multi-Factor Authentication interface cannot load because required arguments were not found.");
					}
				}
				else
				{
					this.SetError("This Multi-Factor Authentication interface cannot load because arguments were not provided correctly.");
				}
			}
			catch (err)
			{
				console.error(err);
				this.SetError("This Multi-Factor Authentication interface cannot load because invalid arguments were provided.");
			}
		},
		mounted()
		{
			let footer = document.getElementById("appFooter");
			if (footer)
				footer.style.display = "none";
			if (this.$refs.mfaInterface)
				this.$refs.mfaInterface.SetFocus();
		},
		methods:
		{
			Cancel()
			{
				// External apps should hook here to handle MFA interface closing.
				if (typeof window.parent.AdminMFACallback === "function")
					window.parent.AdminMFACallback({ cancel: true });
				else if (typeof window.android_mfa_interface !== "undefined" && typeof window.android_mfa_interface.close === "function")
					window.android_mfa_interface.close();
				else if (typeof window.webkit !== "undefined" && typeof window.webkit.messageHandlers !== "undefined"
					&& typeof window.webkit.messageHandlers.hostapp !== "undefined")
				{ // Apple device
					var message = { "cmd": "close" };
					window.webkit.messageHandlers.hostapp.postMessage(message);
				}
			},
			SetError(errorMessage)
			{
				this.error = errorMessage;
				if (errorMessage)
				{
					// External apps should hook here to handle error messages.
					if (typeof window.parent.AdminMFACallback === "function")
						window.parent.AdminMFACallback({ error: errorMessage });
					else if (typeof window.android_mfa_interface !== "undefined" && typeof window.android_mfa_interface.setError === "function")
						window.android_mfa_interface.setError(errorMessage);
					else if (typeof window.webkit !== "undefined" && typeof window.webkit.messageHandlers !== "undefined"
						&& typeof window.webkit.messageHandlers.hostapp !== "undefined")
					{ // Apple device
						var message = { "cmd": "setError", "msg": errorMessage };
						window.webkit.messageHandlers.hostapp.postMessage(message);
					}
				}
			},
			Submitted(args)
			{
				// External apps should hook here to handle MFA data submissions.
				if (typeof window.parent.AdminMFACallback === "function")
					window.parent.AdminMFACallback({ submitArgs: args });
				else if (typeof window.android_mfa_interface !== "undefined" && typeof window.android_mfa_interface.submitted === "function")
					window.android_mfa_interface.submitted(args.mfaMethodType, args.mfaPayload, !!args.mfaDeviceGuid);
				else if (typeof window.webkit !== "undefined" && typeof window.webkit.messageHandlers !== "undefined"
					&& typeof window.webkit.messageHandlers.hostapp !== "undefined")
				{ // Apple device
					var message = { "cmd": "submitted", "mfaMethodType": args.mfaMethodType, "mfaPayload": args.mfaPayload, "mfaDeviceGuid": !!args.mfaDeviceGuid };
					window.webkit.messageHandlers.hostapp.postMessage(message);
				}
			}
		},
		watch:
		{
		}
	}
</script>

<style scoped>
	.mfaHandover
	{
		margin: 25px;
	}

	.loading
	{
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
</style>
