<template>
	<div v-if="myProducts">
		<div class="subHeader">{{heading}}</div>
		<ul>
			<li :role="listItemRole" :tabindex="tabIndex"
				v-for="(item, index) in myProducts" :key="index"
				@click="ItemClick(item)"
				@keydown.space.enter.stop.prevent="ItemClick(item)">
				<PendingLoginProduct :available="available" :item="item" />
			</li>
		</ul>
	</div>
</template>

<script>
	import PendingLoginProduct from "./PendingLoginProduct.vue";
	export default {
		components: { PendingLoginProduct },
		props:
		{
			heading: {
				type: String,
				required: true
			},
			available: {/* If true, list available items.  If false, list unavailable items. */
				type: Boolean,
				required: true
			},
			titles: { /* If true, list the statref titles.  If false, list everything else. */
				type: Boolean,
				required: true
			},
			productDetails: null,
			finishPendingLoginLink: {
				type: String,
				default: null
			}
		},
		data()
		{
			return {
				loading: false
			};
		},
		computed:
		{
			myProducts()
			{
				if (!this.productDetails)
					return null;

				let pList = this.available ? this.productDetails.availableProducts : this.productDetails.unavailableProducts;

				let arr = [];
				for (let i = 0; i < pList.length; i++)
				{
					if (this.titles === pList[i].isStatrefTitle)
						arr.push(pList[i]);
				}
				if (arr.length === 0)
					return null;
				return arr;
			},
			listItemRole()
			{
				return this.available ? "link" : null;
			},
			tabIndex()
			{
				return this.available ? "0" : null;
			}
		},
		methods:
		{
			PreventDefault(e)
			{
				e.preventDefault();
				return false;
			},
			ItemClick(item)
			{
				if (this.loading || !this.available)
					return;
				if (this.productDetails)
				{
					if (this.finishPendingLoginLink)
					{
						this.loading = true;
						window.location.href = this.finishPendingLoginLink + "&productTypeInt=" + item.productType + "&path=" + item.path + "&fromPending=true";
						return;
					}
					else if (typeof (window) !== 'undefined' && typeof (appContext) !== 'undefined')
					{
						if (appContext.finishPendingLoginLink)
						{
							this.loading = true;
							window.location.href = appContext.finishPendingLoginLink + "&productTypeInt=" + item.productType + "&path=" + item.path + "&fromPending=true";
							return;
						}
					}
				}
			}
		}
	}
</script>

<style scoped>
	.subHeader
	{
		font-weight: bold;
	}
</style>