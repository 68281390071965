import { DecryptBytes, Utf8GetString, Base64UrlDecode } from "tdsAppRoot/library/TDSUtil";
import pako from 'pako';

/**
 * Decrypts a payload that was UTF-8 encoded, gzip compressed, and encrypted by the server using AES with very weak password-based key derivation. The password is in the client app so this is very weak security.
 * @param {String} encryptedString Base64Url encoded ciphertext
 * @param {String} password Password used for encryption
 * @returns {String} The original string.
 */
export function DecryptString(encryptedString, password)
{
	let encryptedBytes = Base64UrlDecode(encryptedString);
	let decodedBytes = DecryptBytes(password, encryptedBytes);
	let uncompressedBytes = pako.ungzip(decodedBytes);
	return Utf8GetString(uncompressedBytes);
}