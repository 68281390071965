<template>
	<div>
	</div>
</template>

<script>
	import { ModalEULADialog } from "tdsAppRoot/library/ModalDialog.js";

	export default {
		props:
		{
			returnUrl: { // Mapped to the "path" query string parameter by the router.
				type: String,
				default: null
			}
		},
		mounted()
		{
			ModalEULADialog(true, this.returnUrl);
		}
	}
</script>