<template>
	<div class="forgotPw">
		<h2>Forgot Password</h2>
		<ForgotPasswordBlock :accountType="0" :initialUserName="initialUserName" @requestSent="onRequestSent" />
		<div class="loginLink">
			<router-link :to="{ name: 'loginRoot' }">Return to Login</router-link>
		</div>
	</div>
</template>

<script>
	import ForgotPasswordBlock from "tdsAppRoot/components/Controls/ForgotPasswordBlock.vue";

	export default {
		components: { ForgotPasswordBlock },
		computed:
		{
			initialUserName()
			{
				if (this.$store.state.$loginUserName)
					return this.$store.state.$loginUserName;
				return "";
			}
		},
		methods:
		{
			onRequestSent()
			{
				this.$router.push({ name: 'loginRoot' });
			}
		}
	}
</script>

<style scoped>
	.forgotPw
	{
		max-width: 320px;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		padding: 20px;
		box-sizing: border-box;
	}

	h2
	{
		margin-top: 0px;
		margin-bottom: 10px;
	}

	.loginLink
	{
		margin-top: 20px;
		text-align: center;
	}
</style>