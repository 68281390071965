<template>
	<div class="shibGroupSelectRoot">
		<div v-if="multipleSubsAvailable && (requestedSubAvailable || (shibReqGroup == null || shibReqGroup.length == 0))" class="helpTxt">
			Multiple subscriptions are available to you.<br/>Please choose from the following:
		</div>
		<div v-if="anySubAvailable && !requestedSubAvailable && shibReqGroup != null && shibReqGroup.length > 0" class="helpTxt">
			Your organization has not been configured for access to the requested subscription, but you can <span v-if="multipleSubsAvailable">choose one of these</span><span v-else>access this subscription instead</span>:
		</div>
		<div v-if="!multipleSubsAvailable && requestedSubAvailable" class="helpTxt"> <!-- This is not supposed to happen -->
			Click below to access your subscription:
		</div>
		<div class="subscriptions">
			<a class="subLink" v-for="sub in shibGroups" :href="GetSubUrl(sub)">{{ sub.grpName }}</a>
		</div>
	</div>
</template>

<script>
	import { ReportError, ShowErrorWindow } from "tdsAppRoot/library/ErrorReporter.js";

	export default {
		data: function () {
			return {
				shibGroups: null, //[{"grpalias":"grp1","grpName":"Group One"},{"grpalias":"grp2","grpName":"Group Two"}]
				shibFed: null, // 'UK_Federation'
				shibReqGroup: null, // 'tdsi'
				loaded: false
			}
		},
		mounted: function () {
			if (typeof window !== 'undefined' && typeof (appContext) !== 'undefined' && appContext && appContext.shibGroups) {
				this.$data.shibGroups = appContext.shibGroups;
				this.$data.shibFed = appContext.shibFed;
				this.$data.shibReqGroup = appContext.shibReqGroup;
				this.$data.loaded;
				if (!this.multipleSubsAvailable && this.requestedSubAvailable) {
					// Log in to the requested sub.  This shouldn't happen.
				}
				if (!this.anySubAvailable) {
					ReportError(this.$store.getters.urlRoot, "ShibGroupSelect UI invoked with no subscriptions available."); // This should never happen.  The user should have been redirected to login/error.
					ShowErrorWindow("Your organization has not been configured to access " + appContext.appName + " using Shibboleth authentication.  Please contact STAT!Ref technical support (email: support@statref.com) for assistance.  Please include your organization name, Shibboleth Identification Point Entity ID" + (this.$data.shibFed == "UK_Federation" ? "" : ", and scope") + ".  We apologize for any inconvenience.");
				}
			}
		},
		methods: {
			GetSubUrl: function (sub)
			{
				return this.$store.getters.urlRoot + (this.$data.shibFed == "OpenAthens_Federation" ? "openathens" : "secure") + "/CreateSession?grpalias=" + sub.grpalias + (this.$route.query.target ? "&target=" + this.$route.query.target : "");
			}
		},
		computed: {
			multipleSubsAvailable: function () {
				if (!this.$data.shibGroups)
					return false;
				return this.$data.shibGroups.length > 1;
			},
			anySubAvailable: function () {
				if (!this.$data.shibGroups || this.$data.shibGroups.length == 0) {
					return false;
				}
				return true;
			},
			requestedSubAvailable: function () {
				if (!this.$data.shibGroups)
					return false;
				for (let i = 0; i < this.$data.shibGroups.length; i++) {
					if (this.$data.shibGroups[i].grpalias == this.$data.shibReqGroup)
						return true;
				}
				return false;
			}
		}
	}
</script>

<style scoped>
	.shibGroupSelectRoot
	{
		padding: 10px;
		
		min-width: 320px;
		box-sizing: border-box;
	}
	.subLink
	{
		display: block;
	}
	.helpTxt
	{
		max-width: 400px;
		margin-bottom: 10px;
	}
</style>