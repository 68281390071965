<template>
	<div class="HtmlFilePageContent">
		<div class="logoTopBar">
			<LogoImage v-if="notLoggedIn" :includeDesc="false" :width="200" class="logoImg" logoType="onPrimaryColor" />
		</div>

		<div class="loading" v-if="!contentLoaded" role="presentation" aria-hidden="true">
			<ScaleLoader class="loadingAnimation" />Loading
		</div>
		<div class="loadingMsg" v-if="contentLoaded"></div>
		<div class="root">
			<vsvg v-if="iconSprite" class="iconSprite" :sprite="iconSprite" title="" desc="" role="presentation" aria-hidden="true" />
			<div class="leftSideText">
				<div class="mainTitle">{{ mainTitle }}</div>
				<div class="titleBarLink">{{ supportingText }}</div>
			</div>
		</div>
		<div class="htmlStr" v-html="htmlContent" />
	</div>
</template>


<script>
	import { GetHtmlContent } from 'tdsAppRoot/API/HtmlFilePageAPI.js';
	import ScaleLoader from 'tdsAppRoot/library/3rdParty/ScaleLoader.vue';
	import svg2 from "tdsAppRoot/images/sprite/textbooks.svg";
	import LogoImage from "tdsAppRoot/components/Controls/LogoImage.vue";

	export default {
		components: { ScaleLoader, LogoImage },
		props:
		{
			filetoken: { type: String }
		},
		data()
		{
			return {
				htmlContent: ' ',
				iconSprite: null,
				mainTitle: '',
				supportingText: '',
				contentLoaded: false,
				notLoggedIn: true
			}
		},
		watch:
		{
			'$route': function (to)
			{
				console.log("filetoken: " + this.$props.filetoken);
				this.$data.contentLoaded = false;
				if (this.$store.getters.sid != null && this.$store.getters.sid.length > 10)
					this.$data.notLoggedIn = false;

				this.fetchData();
			}
		},
		mounted()
		{
			console.log("filetoken: " + this.$props.filetoken);
			this.$data.contentLoaded = false;
			if (this.$store.getters.sid != null && this.$store.getters.sid.length > 10)
				this.$data.notLoggedIn = false;

			this.fetchData();
		},
		methods:
		{
			fetchData()
			{
				GetHtmlContent(this.$store, this.$props.filetoken)
					.then((data) =>
					{
						this.$data.htmlContent = data.htmlStr;
						this.$data.mainTitle = data.mainTitle;
						this.$data.supportingText = data.supportingText;
						this.$data.iconSprite = "#" + data.iconSprite;
						this.$data.contentLoaded = true;
						//console.log("Got data for component: " + data.htmlStr);
					})
					.catch(err =>
					{
						console.error("Error getting data for HtmlFilePage: ", err);
					});
			}
		}
	}
</script>

<style>
	/* Unscoped */
	.HtmlFilePageContent h2
	{
		display: block;
		-webkit-margin-start: 0px;
		-webkit-margin-bottom-collapse: collapse;
		margin: 0px 100px 0px;
		font-weight: bold;
		font-size: 1.5em;
	}

	.HtmlFilePageContent ul
	{
		display: block;
	}
</style>

<style scoped>
	.root
	{
		padding: 25px 0px 25px 4px;
		display: flex;
		flex-wrap: wrap;
	}

	.iconSprite
	{
		width: 70px;
		height: 70px;
		fill: var(--primary-color-text);
	}

	.mainTitle
	{
		font-size: 22pt;
		color: var(--primary-color-text);
	}

	.titleBarLink
	{
		color: var(--primary-color-text);
		font-size: 14pt;
		font-weight: bold;
	}

	.leftSideText
	{
		flex: 1 1 auto;
		margin-left: 25px;
		margin-top: 3px;
	}

	.logoTopBar
	{
		background-color: var(--primary-color);
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		width: 100%;
	}

	.logoImg
	{
		display: block;
		margin: 0px auto;
		padding: 20px 0px;
		text-align: center;
		fill: var(--text-color-on-primary-color);
	}

	.HtmlFilePageContent
	{
		margin: 20px 3%;
		border-radius: 10px;
		padding: 15px;
		background-color: #FFFFFF;
		max-width: 1119px;
		box-sizing: border-box;
	}

	.htmlStr
	{
		padding: 0px;
		margin-top: 0px;
		border: hidden;
		width: 100%;
	}

	.loadingAnimation
	{
		display: inline-block;
		margin-right: 20px;
	}

	.loading
	{
		text-align: center;
		font-size: 40pt;
		font-family: 'Open Sans', sans serif;
	}

	@media (min-width: 700px)
	{
		.HtmlFilePageContent
		{
			padding-left: 30px;
			padding-right: 30px;
		}
	}

	@media (min-width: 1165px)
	{
		.HtmlFilePageContent
		{
			margin-left: 36px;
			margin-right: 36px;
		}
	}
</style>
