var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "shibGroupSelectRoot" }, [
    _vm.multipleSubsAvailable &&
    (_vm.requestedSubAvailable ||
      _vm.shibReqGroup == null || _vm.shibReqGroup.length == 0)
      ? _c("div", { staticClass: "helpTxt" }, [
          _vm._v("\n\t\tMultiple subscriptions are available to you."),
          _c("br"),
          _vm._v("Please choose from the following:\n\t")
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.anySubAvailable &&
    !_vm.requestedSubAvailable &&
    _vm.shibReqGroup != null &&
    _vm.shibReqGroup.length > 0
      ? _c("div", { staticClass: "helpTxt" }, [
          _vm._v(
            "\n\t\tYour organization has not been configured for access to the requested subscription, but you can "
          ),
          _vm.multipleSubsAvailable
            ? _c("span", [_vm._v("choose one of these")])
            : _c("span", [_vm._v("access this subscription instead")]),
          _vm._v(":\n\t")
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.multipleSubsAvailable && _vm.requestedSubAvailable
      ? _c("div", { staticClass: "helpTxt" }, [
          _vm._v("\n\t\tClick below to access your subscription:\n\t")
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "subscriptions" },
      _vm._l(_vm.shibGroups, function(sub) {
        return _c(
          "a",
          { staticClass: "subLink", attrs: { href: _vm.GetSubUrl(sub) } },
          [_vm._v(_vm._s(sub.grpName))]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }